.accordion {
    width:100%;
    float:left;
    
    .content-wrapper{
        float:left;
        overflow-x: hidden;
        overflow-y: hidden;
        height:auto;
        margin-top:0px;
        width:100%;
        opacity:0;
        transition: height ease 0.2s, opacity 0.2s ease;
    }
    .content-wrapper.open{
        overflow: visible;
    }
}
.accordion.open {
height: auto;
}