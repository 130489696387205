@font-face {
    font-family: 'Armand Grotesk';
    src: url('./fonts/ArmandGrotesk2022-SemiBold.woff2') format('woff2'),
        url('./fonts/ArmandGrotesk2022-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}



// DEFS

$large-header-font-size:25px;
$large-header-line-height:25px;

$mid-header-font-size:22px;
$mid-header-line-height:25px;

$mid-header-font-size-largescreen:26px;
$mid-header-line-height-largescreen:32px;

$body-font-size:13px;
$body-font-size-largescreen:16px;
$body-line-height-largescreen:21px;

$narrow-body-font-size:15px;
$narrow-body-line-height:22px;

// HELPERS

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
    display: none;  /* Safari and Chrome */
}

.black{
    background-color: black;
    color: white;

    a{
        color:white !important;
        cursor: pointer;
    }
}

.white{
    background-color: white;
    color: black;
}

.large-header{
    font-size: $large-header-font-size;
    line-height: $large-header-line-height;
    transition: font-size 0.3s ease-in-out;
}

.mid-header{
    font-size: $mid-header-font-size;
    line-height: $mid-header-line-height;
}

.center-title{
    text-align: center;
    padding: 20px;
}

.socials {
    grid-template-columns: auto auto;
    justify-content: center;
    grid-gap: 30px;
}

// SECTIONS

body{
    font-family: 'Armand Grotesk';
    font-size:$body-font-size;
    line-height: 18px;
    text-align: justify;
}

a{
    color:black;
}
p:empty{
    height: 18px;
}

.pointer-cursor{
    cursor: pointer;
}

.uppercase{
    text-transform: uppercase;
}

.arrow-title{
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
}
.arrow-title-right{
    transform: rotate(180deg);
    display: inline-block;
}

.third{
    // width:calc(100% / 3);
    // height:100vh;
    overflow-y:scroll;
    float:left;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    transition: width 0.3s ease-in-out;
}
.focus{
    width:calc(100% / 2) !important;
}
// .nofocus{
//     width:calc(100% / 4) !important;
// }
.left{
    width:calc(100% / 3);
 }
.left.focus{
   width: calc(100% / 2) !important;
}
.left.nofocus{
    width:calc(100% / 4) !important;
 }

.right{
    width:calc(100vw - (100vw / 1.5));
}
.right.focus{
    width: calc(100% / 2) !important;
 }

 .middle{
    width:calc(100% / 3);
 }
.middle.nofocus{
    width:calc(100% / 4);

    .large-header{
        font-size: 20px;
        line-height: 20px;
    }
}

.right.nofocus{
    width:calc(100% / 4);

    .large-header{
        font-size: 20px;
        line-height: 20px;
    }
}

.header{
    width: 100%;
    height: calc(100vh / 9);
    display: grid;
    align-content: center;
    text-align: center;
    font-size: $mid-header-font-size;
    position: relative;
    line-height: 25px;
    padding: 0px 20px;

}
.twitter-header{
    height:calc(100vh / 9);
    // width:100%;
    font-size: $body-font-size;
    display: grid;
    align-content: center;
    white-space: nowrap;

    .ticker-content{
        display: block;
        width:100%;
        padding: 0px 20px;
    }
}

.left{

    .close-button{
        position: absolute;
        top:20px;
        right:20px;
        font-size: 20px;
        display: none;
        cursor: pointer;
    }
    .close-button-show{
        display: block !important;
    }

    .left-intro-content-title{
        padding: 20px;
    }

    .left-intro-content{
        // height:calc(100vh / 3);
        display:grid;
        grid-template-rows: 1.5fr auto 1.5fr;
        justify-content: center;
        overflow-y: scroll;
        position: relative;

        .left-intro-content-title{
            display:grid;
            align-content: center;
            justify-content: center;
            text-align: center;

        }
        .left-intro-content-text{
            display:grid;
            align-content: center;
            justify-content: center;
            padding: 0px 30px;
        }
        .left-intro-content-read-more{
            display:grid;
            align-content: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

.left-intro-contact{
    height:calc(100vh / 3);
    // height:auto;
    display:grid;
    grid-template-rows: auto auto;
    transition: height 0.3s ease-in-out;

    .left-intro-contact-title{
        display:grid;
        align-content: center;
        justify-content: center;
        // padding: 20px;
    }
    .left-intro-contact-text{
        padding: 0px 20px;
        height: auto;
        transition: height 0.3s ease-in-out;
        overflow: hidden;
        display: grid;
align-content: center ;
    }
}

.left-intro-contact-contracted{
    height: calc(100vh / 9) !important;

    .left-intro-contact-title{
        height: calc(100vh / 9) !important;

    }
}

.middle{
    overflow-y:scroll;

    .accordion-close-button{
        font-size: 20px;
        position: absolute;
        right:20px;
        top:50%;
        transform: translatey(-50%);
    }

    .accordion-section{
        padding-bottom:30px;

        .accordion-link-section{
            // height: calc(100vh / 9);
            background: #ccc;
            text-align: center;
            padding:20px;
            color:black;
        }

        .accordion-topic-text{
            padding: 30px;
        }

        .accordion-topic-designers-wrapper{
            width: 80%;
            margin: 40px auto;
            margin-top: 0px;

            .accordion-topic-designer{
                border-top: thin solid;
                padding-top: 20px;
                // margin-top:40px;

                .accordion-topic-designer-text{
                    padding: 30px;
                }
            }
        }
    }

    .header:hover{
        cursor:pointer;
        background-color: rgb(131, 131, 131);
        color:black;
    }
    .accordion:nth-child(even) {
        background-color: black;
        color: white;

        a{
            color:white;
        }
    }
    
    .accordion:nth-child(odd) {
        background-color: white;
        color: black;
    }

}

.right{

    .square-section-open{
        height:100vh;
        padding: 30px;
        overflow-y: scroll;
        position: fixed;
        width: calc(100vw / 2);
        z-index: 999;
        background-color: white;


        .square-section-close-button{
            position: absolute;
            top:30px;
            right:30px;
            font-size: 20px;
            // display: none;
            cursor: pointer;
        }
        .square-section-open-content{
            padding-top:20px;

            .square-section-open-title-wrapper{
                text-align: center;
                width:300px;
                margin:20px auto;

                .subtitle:empty{
                    padding:0px;
                }

                .subtitle{
                    padding:20px 0px;
                }
            }

            .square-section-open-text{

            }

            .square-section-open-slideshow{
                border-top:thin solid;
                padding-top:20px;
                width:80%;
                margin:20px auto;
            }
            .square-section-open-credits{
                padding-top:20px;
                width:80%;
                margin:20px auto;
            }
        }
    }

    .square-pair{
        cursor: pointer;
    }

        .square{
            width:50%;
            height:calc(100vh / 3);
            float:left;
            display: grid;
            // grid-template-rows: 30px auto;
            position:relative;

            .square-meta{   
                position: absolute;
                width: 100%;
                padding: 6px 8px;
            }

            .square-content-image-wrapper{
                height:100%;
                width:100%;
                display: grid;
                // grid-template-rows: 30px auto;

                .square-content-image{
                    // width: 100%;
                    // padding:50px 20px 20px 20px;
                    // height: 100%;
                    // position: absolute;
                    // justify-content: center;
                    // display: grid;

                    //
                    display: grid;
                    height: 100%;
                    justify-content: center;
                    position: relative;
                    width: 100%;

                    img{
                        // max-height:100%;
                        // max-width:100%;

                        max-height: 100%;
                        max-width: 100%;
                        position: absolute;
                        padding: 30px;
                        justify-self: center;
                        align-self: center;
                        display: grid;
                    }
                
                }
            }

            .square-content{
                width: 100%;
                float: left;
                position:relative;
                text-align: center;
                display:grid;
                grid-template-rows: auto 50px;

                .square-content-title-wrapper{
                    display: grid;
                    align-content: center;
                    padding:0px 20px;

                    .square-content-title{

                    }
                    .square-content-subtitle{
                        padding-top:20px;
                    }
                }

                .square-content-subtitle-bottom{
                    align-self: end;
                    padding:0px 20px 20px 20px;
                }

                .square-content-text{
                    display: grid;
                    align-content: center;
                }

            }
    }

    .square-pair:nth-child(odd){
        .square:nth-child(odd){
            background-color: black;
            color: white;
        }
        .square:nth-child(even){
            background-color: white;
            color: black;
        }
    }

    .square-pair:nth-child(even){
        .square:nth-child(even){
            background-color: black;
            color: white;
        }
        .square:nth-child(odd){
            background-color: white;
            color: black;
        }
    }

    .square-pair:nth-child(odd):hover{
        .square:nth-child(even){
            background-color: #b3b3b3;
            color: black;
        }
        .square:nth-child(odd){
            background-color: #383838;
            color: white;
        }
    }

    .square-pair:nth-child(even):hover{
        .square:nth-child(odd){
            background-color: #b3b3b3;
            color: black;
        }
        .square:nth-child(even){
            background-color: #383838;
            color: white;
        }
    }

    // .square:nth-child(1), .square:nth-child(4), .square:nth-child(5),.square:nth-child(8){
    //     background-color: black;
    //     color: white;
    // }

    // .square:nth-child(2), .square:nth-child(3), .square:nth-child(6),.square:nth-child(7) {
    //     background-color: white;
    //     color: black;
    // }

    // .square:hover{
    //     cursor:pointer;
    //     background-color: #ccc !important;
    //     color:black !important;
    // }
    
}
@media screen and (min-width:1500px){
    body{
        font-size: $body-font-size-largescreen;
        line-height: $body-line-height-largescreen;
    }
    .mid-header{
        font-size: $mid-header-font-size-largescreen;
        line-height: $mid-header-line-height-largescreen;
    }
    .header{
        font-size: $mid-header-font-size-largescreen;
        // line-height: $mid-header-line-height-largescreen;
    }
}  

@media screen and (max-width:1100px){
    body{
        font-size: $narrow-body-font-size;
        line-height: $narrow-body-line-height;
    }
    .header:hover{
        background-color: inherit !important;
        color: inherit !important;
    }

    .square-section-open-slideshow{
        width:100% !important;
    }
    .accordion-topic-designers-wrapper{
        width: calc(100% - 60px) !important;
    }

    .accordion-topic-designer-text {
        padding: 30px 0px !important;
    }

    .arrow-title-wrapper{
        width:100%;
        text-align: center;
        padding-bottom: 16px;
    }

    .left-intro-content-title{
        white-space: nowrap;
    }

    .left-intro-contact{
        height: auto !important;
        float: left !important;
        width: 100%;
        margin-bottom: 40px;

        .left-intro-contact-title{
            padding: 30px;
            text-transform: uppercase;
        }
    }

    .narrow-news-button{
        background-color: black;
        color:white;
    }
    .narrow-news-button:hover{
        background-color: black !important;
        color:white !important;
    }

    .fixed{
        position: fixed;
        width:100%;
        top:0;
        z-index: 10;
    }

    .left{
        margin-top: calc(100vh / 9);
    }
    .third{
        width:100% !important;
        // height:auto !important;

        .left-intro-content{
            height: auto !important;
            display: block !important;
            padding:40px;

            // .left-intro-content-title{
            //     padding-bottom: 40px;
            // }

            .left-intro-content-read-more{
                padding-top:40px;
            }

            .narrow-about-text{
                height:0;
                overflow-y: hidden;
                transition: height 0.3s ease-in-out;
            }
    
            .narrow-about-text-show{
                padding-top: 40px;
                height: auto !important;
            }
        }

        .narrow-topics-button{
            text-align: center;
            padding:20px;
        }

        .narrow-topics-wrapper{
            height:0;
            display: none;
        }

        .narrow-topics-show{
            height:auto;
            display:block
        }

        .narrow-news-square{

            .square{
                width: 100% !important;
                height: 100vw !important;
            }

            .narrow-large-header{
                text-align: center;
                padding-bottom:16px;
            }
            .narrow-subtitle{
                text-align: center;
                padding-bottom:16px;
            }
        }

        .square-section-open{
            height: auto !important;
            width:100% !important;
            overflow-y: scroll;
            position: relative !important;
        }

        .narrow-news-square:nth-child(even){
            .square,.square-section-open{
                background-color: white;
                color:black
            }
        }
        .narrow-news-square:nth-child(odd){
            .square, .square-section-open{
            background-color: black !important;
            color:white;
            }
        }
    }
}