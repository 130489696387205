.slideshow-wrapper{
    // max-height: calc(100vh - 200px);
    width: 100%;
    position: relative;

    .swiper-ref-prev{
        position: absolute;
        z-index: 9;
        width: 200px;
        height: 100%;
        // background-color: blue;
        top:0;
        left:0;
        display: grid;
        align-items: center;
        // text-align: left;
        justify-content: start;
        cursor: pointer;
    }

    .swiper-ref-next{
        position: absolute;
        z-index: 9;
        width: 200px;
        height: 100%;
        // background-color: blue;
        top:0;
        right:0;
        display: grid;
        align-items: center;
        text-align: right;
        cursor: pointer;
    }

    .slideshow-controls{
        position: absolute;
        top:0px;
        right:0px;
        z-index: 10;

        .slideshow-image-scale{
            text-align: right;
            padding-top: 7px;
            font-size: 20px;
            cursor:pointer;
        }
    }

    .swiper{
        height: 100%;

        .swiper-wrapper{
            height: 100%;

            .swiper-slide{
                height: auto;
            }
        }
    }


    .slideshow-wrapper-image{
        display: grid;
        width: 100%;
        height: 100%;
        align-content: center;
        justify-content: center;

        img{
            height:auto;
            width: auto;
            max-width: 100%;
            max-height: 100%;
            transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
            margin: 0 auto;
        }
        .slideshow-wrapper-image-caption{
            // float: left;
            width:100%;
            text-align: center;
            padding: 20px 0px;
        }
    }
}

@media screen and (max-width:1100px){
    .swiper-ref-prev{
        display: none !important;
    }
    .swiper-ref-next{
        display: none !important;
    }

}